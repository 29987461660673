﻿@use 'sass:math';
@import 'variables';
@import 'mixins';

.inner-layout-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.body-default .department-publish .web-pub-field:first-child {
    padding-top: 10px;
}

.modal-body .container {
    width: 100%;
}

/**** Publiseringsmodus og slideshow ****/
.WebPubFieldRow {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.WebPubElement {
    margin-bottom: 5px;

    @include fromSizeSM() {
        margin-bottom: 10px;
    }
}

.slides_container .WebPubElement {
    margin-bottom: 0 !important;
}

.headroom .WebPubElement:last-child {
    margin-bottom: 0;
}

.royalSlider .WebPubFieldRow.LayoutVersion4 {
    display: none;
}

.royalSlider .WebPubFieldRow.LayoutVersion4:first-child {
    display: flex;
}

.royalSlider .rsContainer .WebPubFieldRow.LayoutVersion4 {
    display: flex;
}

.layout-d4_x2 {
    padding-left: 0px;
    padding-right: 0px;

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    >.row .inner-layout-container > .WebPubElement,
    .slides_container .WebPubElement {
        width: calc(50% - 5px);
        margin-left: 0;
        margin-right: 10px;

        &:nth-child(2n) {
            margin-right: 0;
        }
    }
}

.layout-d4_x3 {
    padding-left: 0px;
    padding-right: 0px;

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    >.row .inner-layout-container > .WebPubElement,
    .slides_container .WebPubElement {
        width: calc(33.33% - 6.67px);
        margin-right: 10px;

        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}

.layout-d4_x4 {
    padding-left: 0px;
    padding-right: 0px;

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    >.row .inner-layout-container > .WebPubElement,
    .slides_container .WebPubElement {
        width: calc(25% - 7.6px);
        margin-left: 0;
        margin-right: 10px;

        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}

.layout-d4_x5 {
    padding-left: 0px;
    padding-right: 0px;

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    >.row .inner-layout-container > .WebPubElement,
    .slides_container .WebPubElement {
        width: calc(20% - 8.5px);
        margin-left: 0;
        margin-right: 10px;

        &:nth-child(5n) {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.layout-d4_x6 {
    >.row .inner-layout-container > .WebPubElement,
    .slides_container .WebPubElement {
        width: calc(16.66% - 8.5px);
        margin-left: 0;
        margin-right: 10px;

        &:nth-child(6n) {
            margin-right: 0;
        }
    }
}

.layout-d4_x7 {
    > .row .inner-layout-container > .WebPubElement,
    .slides_container .WebPubElement {
        width: calc(14.285% - 60px/7);
        margin-left: 0;
        margin-right: 10px;

        &:nth-child(7n) {
            margin-right: 0;
        }
    }
}

/* D4 Fluid layout*/
$margin: 10px;
$maxWidth: 3840px;
$elementWidth: 300px;
$maxColumns: floor(math.div($maxWidth, $elementWidth));
$leftSideWidth: 250px;
$maxWidth: $elementWidth * $maxColumns;

.layout-d4_fluid {
    padding-left: 0px;
    padding-right: 0px;

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    >.row .inner-layout-container > .WebPubElement,
    .slides_container .WebPubElement {
        margin-right: $margin;
    }
}

@for $i from 1 through $maxColumns {
    @media (min-width: ($elementWidth * $i)) {
        .layout-d4_fluid > .row .inner-layout-container > .WebPubElement,
        .layout-d4_fluid .slides_container .WebPubElement {
            width: calc(#{math.div(100%, $i)} - #{math.div($margin * ($i - 1), $i)});
            margin-right: $margin;

            &:nth-child(#{$i - 1}n) {
                margin-right: $margin;
            }

            &:nth-child(#{$i}n) {
                margin-right: 0;
            }
        }
    }
}

.ColumnCenter .layout-d4_fluid .inner-layout-container {
    padding: 0 10px;
}

.pub-field-hide-xs,
.pub-field-hide-sm,
.pub-field-hide-md,
.pub-field-hide-lg,
.pub-field-hide-xl {
    display: none;
}



.ie9 {
    .WebPubElement {
        float: left;
    }
}

.preview-slideshow.web-pub-field.container .slides_container.ajax-field .WebPubElement {
    width: 100% !important;
}

.d4-listing-container {
    display: block;
}

.d4-listing-row {
    display: table-row;
    width: 100%;
    text-align: left;
    border-radius: 0px;
    border-width: 0px;
    border-top: 1px solid #DDDDDD;
    padding-top: 10px;

    .producer-logo {
        position: absolute;
        z-index: 9;
        bottom: 0px;
        right: 0px;

        @include toSizeSM() {
            display: none;
        }
    }

    .AddStockContainer {
        position: static;
        right: unset;
        bottom: unset;
    }

    .AdProductLink:hover {
        text-decoration: none;
    }

    .product-desc1 {
        color: $textColor;
        display: block;
        clear: both;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
    }

    .product-desc2 {
        color: $textColor;
        display: block;
        clear: both;
    }

    .product-desc-prod-num-lbl {
        display: inline-block;
        margin-right: 5px;
    }

    .button-container {
        cursor: pointer;

        .btn {
            margin-left: 1px;
            border-radius: 0px;
        }
    }

    .btn-primary a {
        color: white;
    }
}

.d4-listing-cell {
    display: table-cell;
    /*height: 100%;
    float: none;*/
    vertical-align: middle;

    .YouSavePercentLabel {
        font-size: 12px;
        width: 36px;
        height: 36px;
        padding-top: 10px;
        text-align: center;
        top: 0px;
        right: 0px;
        position: absolute;
        z-index: 1;
        font-weight: bold;
        background: #e6b74b;
        border-radius: 50%;
        display:none;
    }

    &.show{
        display:block;
    }

    .ProduktImg {
        max-width: 120px;
    }

    &.image-cell {
        .ProduktImg img {
            @include toSizeSM() {
                max-width: 60px;
            }
        }
    }

    &.description-cell {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
    }

    &.price-cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: right;

        .old-price-container {
            display: block;
            text-decoration: line-through;
        }
    }

    &.qty-cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        @include toSizeSM() {
            display: none;
        }

        label {
            font-size: 8px;
            font-weight: normal;
            width: 32px;
            white-space: normal;
            text-align: center;
            display: none;
        }

        .qty-carts {
            width: auto;
            white-space: nowrap;
        }

        .qty-container {
            display: inline-block;
            white-space: nowrap;
            margin-right: 4px;
        }

        .qty-textbox {
            text-align: center;
            border: 1px solid #ddd;
            height: 32px;
            padding: 0;
            display: block;
            width: 32px;
            font-size: 16px;
        }
    }

    &.action-cell {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .btn.variant-matrix-button,
        .btn.list-buy-button {
            @include toSizeSM() {
                display: none;
            }

            width: 66px;
            max-width: 66px;

            &:first-child {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            &:last-child:first-child {
                border-top-right-radius: inherit;
                border-bottom-right-radius: inherit;
            }
        }

        .btn.variant-matrix-button.orderbook {
            @include toSizeSM() {
                display: none;
            }

            width: 100px;
            max-width: 100px;
            margin-left: 1px;

            &:nth-child(2) {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            &:last-child {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: inherit;
                border-bottom-right-radius: inherit;
            }
        }

        .btn.variant-matrix-button.orderbook {
        }

        .favorite-list-btn {
            margin-left: 1px;
        }

        .fav-remove-button {
            display: none;
        }
    }

    .AddAttributeIconContainer .att-icon {
        background-size: contain;
        max-width: 32px;
        max-height: 32px;
    }
}

.btn-group .btn.favorite-list-btn {
    margin-top: 0px;
}


.pub-favorites .d4-listing-cell.action-cell .fav-remove-button {
    display: inline-block;
    margin-left: 1px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.pub-favorites .d4-listing-cell.action-cell .favorite-no-buy {
    display: none;
}

$oddColBg: #f8f8f8;
$evenColBg: #fdfdfd;
$firstRowTopPadding: 30px;

#product-variant-matrix {
    padding: 15px;

    h1 {
        font-size: 26px;
    }

    .product-navigation-container {
        &.top {
            margin-bottom: 1em;
        }
    }

    .list-sort-container {
        margin-bottom: 10px;
    }

    .previous-product, .next-product {
        width: 75px;
        display: inline-block;
        margin-left: 10px;

        a, button {
            display: block;
            margin: auto;
        }
    }

    .orderbook-navigation-txt {
    }

    .WebPubElement:nth-child(odd) {
        background: rgba($oddColBg, 0.5);
    }

    .d4-listing-row {
        padding-bottom: 10px;
    }

    .qty-container.active-col:nth-child(odd),
    .qty-container.active-col:nth-child(even) {
        background: #39c;
        box-shadow: 0px 5px 10px rgba(160,160,160, 0.6);
        width: 56px;
        text-align: center;
        padding: 2px 2px 2px 2px;
    }

    .qty-cell {
        height: 100%;
    }

    .qty-carts {
        height: 100%;
    }

    .qty-container.active-cell {
        input {
            width: 50px !important;
            height: 50px !important;
            font-size: 18px;
        }

        label {
            position: absolute;
            padding: 2px;
            font-size: 14px;
            /*border: 1px #888 solid;*/
            display: inline-block;
            background: white;
            width: 100px;
            border-radius: 3px;
            /*box-shadow: 5px 5px 20px #888;*/
            z-index: 1;
            margin-left: -30px;
            background: #39c;
            color: white;
        }
    }

    .WebPubElement:first-child {
        .d4-listing-cell {
            padding-top: $firstRowTopPadding;
        }

        .qty-container {
            label {
                display: inline-block;
                position: absolute;
                font-size: 12px;
                position: absolute;
                width: auto;
                padding: 2px;
                border-style: solid;
                border-width: 1px 1px 0px 1px;
                margin-left: -12px;
            }

            &.active-cell label {
                margin-left: -6px;
            }

            &:nth-child(odd) label {
                background: $oddColBg;
                margin-top: calc(0px - #{$firstRowTopPadding});
                border-color: darken($oddColBg, 5%);
            }

            &:nth-child(even) label {
                background: $evenColBg;
                margin-top: calc(22px - #{$firstRowTopPadding});
                border-color: darken($evenColBg, 5%);
            }

            &.active-col:nth-child(even),
            &.active-col:nth-child(odd) {

                label {
                    background: #39c;
                    color: white;
                    text-shadow: 1px 1px 2px rgba(0,0,0,0.7);
                    border-width: 0px;
                    padding-top: 5px;
                    z-index: 10;
                }
            }

            &.active-col:nth-child(odd) {
                padding: 10px 2px 2px 2px;
            }

            &.active-col:nth-child(even) {
                padding: 2px 2px 2px 2px;
            }
        }
    }
    /*.WebPubElement:first-child .qty-container.active-col:nth-child(even) label,
    .WebPubElement:first-child .qty-container.active-col:nth-child(odd) label {
        background: #39c;
        color: white;
        border-width: 0px;
        padding-top: 5px;
        z-index: 10;
    }*/
    .WebPubElement {
        margin-bottom: 0px;
    }

    .qty-container {
        height: calc(100% + 20px);
        display: inline-block;
        text-align: center;
        margin-top: -10px;
        margin-bottom: -10px;
        padding: 10px 0px;
        vertical-align: middle;

        label {
            clear: both;
            display: none;
            position: absolute;
        }
    }

    .qty-container:nth-child(even) {
        background: $evenColBg;
    }

    .qty-container:nth-child(odd) {
        background: $oddColBg;
    }

    .QuantityTextBox {
        text-align: center;
        display: inline-block;
        width: 30px !important;
        height: 30px !important;
        top: 50%;
        position: relative;
        transform: translateY(-50%);
        float: none;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            transform: translateY(50%);
        }
    }

    .private-area,
    .business-area {
        .WebPubElement:first-child {
            .d4-listing-cell {
                padding-top: 0px;
            }
        }
    }
}

#orderbook-summary-container {
    table {
        .amount,
        .delete-orderbook,
        .change-orderbook,
        .amountSum {
            text-align: center;
        }

        .price,
        .priceTotal {
            text-align: right;
        }

        th.price {
            padding-right: 0.9em;
        }
    }
}

#secondary-filter {
    #Pnl1, #Pnl2 {
        width: auto;
        margin-right: 0.5em;
        display: inline-block;
    }
}

#favPopup {
    text-align: left;
}

#favourites-dropdown-container.dropdown {
    display: none;
}

#favourites-dropdown-container.dropdown.open {
    display: block;
}

.fav-selector {
    display: inline-block;
}

.dropdown-placeholder {
    position: absolute;
}

.dropdown .dropdown-toggle .caret {
    margin-left: 0.8em;
}

.d4-instant-search-categories, d4-instant-search-brands {
    width: 25%;
    background-color: white;
    float: right;
}

.InstantSearch span.d4-instant-search-category,
.InstantSearch span.d4-instant-search-brand,
.InstantSearch span.d4-instant-search-article {
    margin-right: 10px;
    line-height: 1.8;
}

.InstantSearch span.d4-instant-search-article{
    display:block;    
}
.InstantSearch .d4-instant-search-loadbyajax .container {
    width: initial !important;
}
.InstantSearch table {
    width: 49%;
}

.LayoutVersion4.preview-element.preview-visible .WebPubElement {
    width: 100% !important;
    margin-left: initial !important;
    height: 700px;
}

.preview-slideshow.web-pub-field.container {
    position: relative;
}

.ajax-field .preview-slideshow #slider-next-button {
    background-image: url('/css/Demonstrare4/images/royalslider/slider-right.png');
    position: absolute;
    right: 5px;
    z-index: 100;
    width: 44px;
    height: 44px;
    top: 200px;
}

.ajax-field .preview-slideshow #slider-close-button {
    background-image: url('/css/images/icon_remove.png');
    background-repeat:no-repeat;
    position: absolute;
    right: 20px;
    z-index: 100;
    width: 15px;
    height: 15px;
    top: 10px;
}

.ajax-field .preview-slideshow #slider-previous-button {
    background-image: url('/css/Demonstrare4/images/royalslider/slider-left.png');
    position: absolute;
    left: 5px;
    z-index: 100;
    width: 44px;
    height: 44px;
    top: 200px;
}


/* 3 columns, 2x2 every 6th content
        1       2       3
    +-------+-------+-------+
    |       |               |
    |       |               |
    |       |               |
    +-------+       2x2     |
    |       |               |
    |       |               |
    |       |               |
    +-------+-------+-------+
    |       |       |       |
    |       |       |       |
    |       |       |       |
    +-------+-------+-------+
*/

.layout-d4_x3_2x2 {
    padding-left: 0px;
    padding-right: 0px;

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .inner-layout-container {
        display: grid;
        grid-gap: 10px 10px;
        grid-template-columns: 1fr 1fr 1fr;
        margin-bottom: 10px;
    }

    > .row .inner-layout-container > .WebPubElement {
        margin: 0px;
        width: auto;
        min-width: 0px;
        min-height: 0px;
        grid-column-end: span 1;
        grid-row-end: span 1;
        display: block;

        &:nth-child(6n-4) {
            grid-column-end: span 2;
            grid-row-end: span 2;

            & .AddProductImage {
                height: 100%;
                & img {
                    max-height: initial;
                }
            }

            &:last-child {
                grid-row-end: span 1;
            }
        }

        .D4Standard {
            height: 100%;
        }
    }

    .preview-slideshow.layout-d4_x3_2x2 {
        grid-column-end: span 3;
        grid-row-end: span 2;
        width: 100%;

        .inner-layout-container {
            display: block;
        }
    }
    /* Targeting IE */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
        padding-left: 0px;
        padding-right: 0px;

        .inner-layout-container {
            display: flex;
        }

        .row {
            margin-left: 0px;
            margin-right: 0px;
        }

        > .row .inner-layout-container > .WebPubElement,
        .slides_container .WebPubElement {
            width: calc(33.33% - 6.67px);
            margin-right: 10px;
            margin-bottom: 10px;

            &:nth-child(3n) {
                margin-right: 0;
            }

            .D4Standard {
                height: auto;
            }
        }
    }
}

/* ========================================== */

/* 4 columns, 2x2 every 9th content
        1       2       3       4
    +-------+-------+-------+-------+
    |       |               |       |
    |       |               |       |
    |       |               |       |
    +-------+       2x2     |-------+
    |       |               |       |
    |       |               |       |
    |       |               |       |
    +-------+-------+-------+-------+
    |       |       |       |       |
    |       |       |       |       |
    |       |       |       |       |
    +-------+-------+-------+-------+
*/

.layout-d4_x4_2x2 {
    /*    @include mobileLayout(2, 5px); */
    @include toSizeMD() {
        padding-left: 0px;
        padding-right: 0px;

        .row {
            margin-left: 0px;
            margin-right: 0px;
        }

        .inner-layout-container {
            display: grid;
            grid-gap: 10px 10px;
            grid-template-columns: 1fr 1fr 1fr;
            margin-bottom: 10px;
            grid-auto-flow: row dense;
        }

        > .row .inner-layout-container > .WebPubElement {
            margin: 0px;
            width: auto;
            min-width: 0px;
            min-height: 0px;
            grid-column-end: span 1;
            grid-row-end: span 1;
            display: block;

            &:nth-child(9n-7) {
                grid-column-end: span 2;
                grid-row-end: span 2;

                &:last-child {
                    grid-row-end: span 1;
                }
            }

            .D4Standard {
                height: 100%;
            }
        }

        .preview-slideshow.layout-d4_x4_2x2 {
            grid-column-end: span 3;
            grid-row-end: span 2;
            width: 100%;

            .inner-layout-container {
                display: block;
            }
        }
    }

    @include fromSizeMD() {
        padding-left: 0px;
        padding-right: 0px;

        .row {
            margin-left: 0px;
            margin-right: 0px;
        }

        .inner-layout-container {
            display: grid;
            grid-gap: 10px 10px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            margin-bottom: 10px;
            grid-auto-flow: row dense;
        }

        > .row .inner-layout-container > .WebPubElement {
            margin: 0px;
            width: auto;
            min-width: 0px;
            min-height: 0px;
            grid-column-end: span 1;
            grid-row-end: span 1;
            display: block;

            &:nth-child(9n-7) {
                grid-column-end: span 2;
                grid-row-end: span 2;

                & .AddProductImage {
                    min-height: 550px;
                    height: 100%;
                    & img {
                        max-height: initial;
                    }
                }

                &:last-child {
                    grid-row-end: span 1;
                }
            }

            .D4Standard {
                height: 100%;
            }
        }

        .preview-slideshow.layout-d4_x4_2x2 {
            grid-column-end: span 4;
            grid-row-end: span 2;
            width: 100%;

            .inner-layout-container {
                display: block;
            }
        }
    }
    /* Targeting IE */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        padding-left: 0px;
        padding-right: 0px;

        .row {
            margin-left: 0px;
            margin-right: 0px;
        }

        .inner-layout-container {
            display: flex;
        }

        > .row .inner-layout-container > .WebPubElement, .slides_container .WebPubElement {
            width: calc(25% - 7.6px);
            margin-left: 0;
            margin-right: 10px;
            margin-bottom: 10px;

            &:nth-child(4n) {
                margin-right: 0;
            }
        }
    }
}

.layout-d4_x3_2x1 {
    padding-left: 0px;
    padding-right: 0px;

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    > .row .inner-layout-container > .WebPubElement,
    .slides_container .WebPubElement {
        width: calc(66.66% - 5px);
        margin-right: 10px;

        &:nth-child(2n) {
            margin-right: 0;
            width: calc(33.33% - 5px);
        }
    }
}

.layout-d4_x3_1x2 {
    padding-left: 0px;
    padding-right: 0px;

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    > .row .inner-layout-container > .WebPubElement,
    .slides_container .WebPubElement {
        width: calc(33.33% - 5px);
        margin-right: 10px; 

        &:nth-child(2n) {
            margin-right: 0;
            width: calc(66.66% - 5px);
        }
    }
}

.layout-d4_x4_2x1x1 {
    padding-left: 0px;
    padding-right: 0px;

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    > .row .inner-layout-container > .WebPubElement,
    .slides_container .WebPubElement {
        width: calc(25% - 7.5px);
        margin-right: 10px;

        &:nth-child(3n-2) {
            width: calc(50% - 5px);
        }

        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}

.layout-d4_x4_1x1x2 {
    padding-left: 0px;
    padding-right: 0px;

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    > .row .inner-layout-container > .WebPubElement,
    .slides_container .WebPubElement {
        width: calc(25% - 7.5px);
        margin-right: 10px;

        &:nth-child(3n) {
            margin-right: 0;
            width: calc(50% - 5px); 
        }
    }
}

.layout-d4_x4_3x3,
.layout-d4_x4_3x3r {
    padding-left: 0px;
    padding-right: 0px;

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .inner-layout-container {
        display: grid;
        grid-gap: 10px 10px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-bottom: 10px;
    }

    > .row .inner-layout-container > .WebPubElement {
        margin: 0px;
        width: auto;
        min-width: 0px;
        min-height: 0px;
        grid-column-end: span 1;
        grid-row-end: span 1;
        display: block;

        .D4Standard {
            height: 100%;
        }
    }

    .preview-slideshow.layout-d4_x4_3x3 {
        grid-column-end: span 3; // TODO
        grid-row-end: span 3; // TODO
        width: 100%;

        .inner-layout-container {
            display: block;
        }
    }
    /* Targeting IE */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
        padding-left: 0px;
        padding-right: 0px;

        .inner-layout-container {
            display: flex;
        }

        .row {
            margin-left: 0px;
            margin-right: 0px;
        }

        > .row .inner-layout-container > .WebPubElement,
        .slides_container .WebPubElement {
            width: calc(25% - 7.5px);
            margin-right: 10px;
            margin-bottom: 10px;

            &:nth-child(3n) {
                margin-right: 0;
            }

            .D4Standard {
                height: auto;
            }
        }
    }
}
.layout-d4_x4_3x3 {
    > .row .inner-layout-container > .WebPubElement {
        &:nth-child(4n-2) {
            grid-column-end: span 3;
            grid-row-end: span 3;

            & .AddProductImage {
                height: 100%;

                & img {
                    max-height: initial;
                }
            }

            &:last-child {
                grid-row-end: span 1;
            }
        }
    }
}

.layout-d4_x4_3x3r {
    > .row .inner-layout-container > .WebPubElement {
        &:nth-child(4n-3) {
            grid-column-end: span 3;
            grid-row-end: span 3;

            & .AddProductImage {
                height: 100%;

                & img {
                    max-height: initial;
                }
            }

            &:last-child {
                grid-row-end: span 1;
            }
        }
    }
}


@include fromSizeMD() {

    .layout-d2016_x1,
    .layout-d2016_x2,
    .layout-d2016_x3,
    .layout-d2016_x4,
    .layout-d2016_x5,
    .layout-d2016_x6,
    .layout-d2016_fluid,
    .layout-d4_x1,
    .layout-d4_x2,
    .layout-d4_x3,
    .layout-d4_x4,
    .layout-d4_x5,
    .layout-d4_x6,
    .layout-d4_x7,
    .layout-d4_x3_2x1,
    .layout-d4_x3_1x2,
    .layout-d4_x4_3x3,
    .layout-d4_x4_3x3r {
        padding-left: 0px;
        padding-right: 0px;

        .row {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
}

/*ugly hax to get fullscreen slideshow in quickview */
html[style='overflow: hidden; height: 100%; width: 100%; margin: 0px; padding: 0px;'] .modal-dialog.modal-lg {
    width: 100%;
    height: 100%;
    top: 0px !important;
    left: 0px !important;
    transform: none !important;
}

.navigator {
    display: flex;
}